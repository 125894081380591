var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"homeCarousel"}},[(_vm.items.length > 0)?_c('VueAgile',{ref:"main",staticClass:"contain-slide",attrs:{"fade":true,"dots":false}},[_vm._l((_vm.items),function(slide,i){return _c('div',{key:slide.folio,staticClass:"slide",attrs:{"index":i}},[_c('img',{staticClass:"card-img image-slide-back",attrs:{"src":slide.largefile,"alt":slide.alt}}),_c('div',{staticClass:"card-img-overlay slide-img-overlay cc_pointer",on:{"click":function($event){slide.type != 1
            ? _vm.goToProperty(slide.folio, slide.propertyNameEs)
            : _vm.goToDevelopment(slide.folio, slide.developmentNameEs)}}},[_c('img',{staticClass:"image-slide",attrs:{"src":slide.largefile,"alt":slide.alt}}),_c('div',{staticClass:"row no-gutters slider-text align-items-md-end align-items-center justify-content-center up"},[_c('div',{staticClass:"col-md-4 text p-4 ftco-animate fadeInUp ftco-animated style-title"},[_c('a',{staticClass:"bg-primary p-3 px-4 btn-custom mb-0",on:{"click":function($event){slide.type != 1
                    ? _vm.goToProperty(slide.folio, slide.propertyNameEs)
                    : _vm.goToDevelopment(slide.folio, slide.developmentNameEs)}}},[_vm._v(" Más detalles "),_c('span',{staticClass:"icon-plus ml-1"})]),_c('h1',{staticClass:"mb-2 cc_pointer style-text p-5 mt-2",on:{"click":function($event){slide.type != 1
                    ? _vm.goToProperty(slide.folio, slide.propertyNameEs)
                    : _vm.goToDevelopment(slide.folio, slide.developmentNameEs)}}},[_vm._v(" "+_vm._s(slide.type != 1 ? slide.propertyNameEs : slide.developmentNameEs)+" ")])])]),(_vm.info.logo)?_c('div',{staticClass:"watermark cc_pointer",style:('background-image:url(' + _vm.info.logo + '); '),on:{"click":function($event){slide.type != 1
              ? _vm.goToProperty(slide.folio, slide.propertyNameEs)
              : _vm.goToDevelopment(slide.folio, slide.developmentNameEs)}}}):_vm._e()])])}),_c('template',{slot:"prevButton"},[_c('i',{staticClass:"fas fa-chevron-left"})]),_c('template',{slot:"nextButton"},[_c('i',{staticClass:"fas fa-chevron-right"})])],2):_c('Preloader')],1)}
var staticRenderFns = []

export { render, staticRenderFns }