<template>
  <!-- Listing Item -->

  <!-- Listing Item -->
  <div class="item">
    <div class="properties " @click="goToProperty(item.folio, item.namePropertyEs)">
      <div v-if="info.logo" class="watermark" :style="'background-image:url('+info.logo+'); '"> </div>
      <a
        @click="goToProperty(item.folio, item.namePropertyEs)"
        :style="'background-image: url(' + item.smallphoto + ');'"
        class="img d-flex justify-content-center align-items-center"
        
      >
        <div class="icon d-flex justify-content-center align-items-center">
          <span class="icon-search2"></span>
        </div>
      </a>

      <div class="text p-3">
        <span class="status status sale" v-if="item.operation"> {{item.operation}}</span>
        
        <div class="d-flex">
          <div class="one">
            <h3>
              <a
                @click="
                  goToProperty(item.folio, item.namePropertyEs)
                "
                >{{ item.namePropertyEs }}</a
              >
            </h3>
            <!--<p>VIVIENDA</p>-->
          </div>
        </div>

        <div class="d-flex">
          <div class="two">
            <span class="price" v-if="item.prices.sold"> 
             {{ item.prices.sold }} {{ item.currenname }}
            </span>
            <span class="price" v-else-if="item.prices.opportunity_rent">
              {{ item.prices.opportunity_rent }} {{ item.currennamePerMonth }}
            </span>
            <span class="price" v-else-if="item.prices.vacation_rent">
              {{ item.prices.vatation_rent }} {{ item.vacation_rental_currency }}
            </span>
          </div>
        </div>
        <!--<p>2 plantas, sala, comedor, 2 1/2 baños, 3 recámaras y espacio para 2 coches.</p>
          <p> Metros en construcción: 113 m2</p>-->
        <hr />
        <p
          class="bottom-area d-flex"
          v-if="
            item.m2c != 0 ||
              item.mlot != 0 ||
              item.bedrooms != 0 ||
              item.bathrooms != 0
          "
        >
          <span v-if="item.m2c > 0"
            ><i class="flaticon-selection"></i> {{ item.m2c }} m
            <sup>2</sup>c</span
          >
          <span v-else-if="item.mlot > 0"
            ><i class="flaticon-selection"
              >{{ item.mlot}} m <sup>2</sup>c</i
            ></span
          >
          <span class="ml-auto" v-if="item.bathrooms > 0"
            ><i class="flaticon-bathtub"></i> {{ item.bathrooms }}</span
          >
          <span v-if="item.bedrooms > 0"
            ><i class="flaticon-bed"></i>{{ item.bedrooms }}</span
          >
        </p>
      </div>
    </div>
  </div>

  <!-- Listing Item / End -->
</template>

<script>
import { mapGetters } from "vuex";
import services from "@/store/_services";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mainPrice: 0,
      secondPrice: 0,
      mainOperation: 0,
      secondOperation: 0,
    };
  },
  computed: {
    ...mapGetters({
      info:'_getInfoCompany',
    }),
    priceInfo() {
      //Formateo de decimales
      let decimalPrice = this.mainPrice.lastIndexOf(".");
      //Extaracción de moneda
      let currentPriceM = this.mainPrice.indexOf("MXN");
      let currentPriceU = this.mainPrice.indexOf("USD");

      // Precio formateado value.substr(currentPrice)
      let formatPrice = this.mainPrice.substr(0, decimalPrice) + " ";
      if (currentPriceM != "-1") {
        formatPrice += this.mainPrice.substr(currentPriceM);
      } else {
        formatPrice += this.mainPrice.substr(currentPriceU);
      }
      return formatPrice;
    },
  },
  
  methods: {
    goToProperty() {
      let namePropFilt = services.normalize(this.item.namePropertyEs);
      let folio = this.item.folio;
      this.$router.push({
        name: "Property",
        params: {
          folio,
          namePropFilt,
        },
      });
    },
  },
  created() {
    /* For Base Template*/
    let prices = this.item.prices;
    let operations = this.item.operations;
    if (prices.opportunity_sold) {
      this.mainPrice = prices.opportunity_sold;
      this.mainOperation = operations.opportunity_sold;
      this.secondPrice = prices.sold;
      this.secondOperation = operations.sold;
    } else if (prices.opportunity_rent) {
      this.mainPrice = prices.opportunity_rent;
      this.mainOperation = operations.opportunity_rent;
      this.secondPrice = prices.rent;
      this.secondOperation = operations.rent;
    } else if (prices.sold) {
      this.mainPrice = prices.sold;
      this.mainOperation = operations.sold;
    } else if (prices.rent) {
      this.mainPrice = prices.rent;
      this.mainOperation = operations.rent;
    }
  },
};
</script>



<style scoped>
  .item{
    width: fit-content;
  }

  

.watermark{
    height: 250px;
    width: 92%;
    background-size: 15%;
    z-index: 1;
    cursor: pointer;
}

.watermark{
  opacity: .6;
}

</style>
